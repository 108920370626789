import { useEffect } from "react"

import { useDrag, useDragLayer } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { Icon, MaybeElement } from "@blueprintjs/core"

import { PageNumberLabel, ThumbnailPreview } from "components/pages"
import { ThumbnailPreviewIcon } from "components/item-detail/ui"
import EmbeddedAttachmentsIcon from "icons/embedded-attachments.svg"

import PageLabel from "./PageLabel"
import WithLabelForItemType from "./WithLabelForItemType"
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16"
import { TPage } from "./VersionForm"
import { UIItemPage } from "models/UIItem"
import EmbeddedAttachmentsPagePreviews from "./EmbeddedAttachmentPagePreviews"

export const dragTypeForPage = {
  inserted_page: "EXECUTED_SIGNATURE_PAGE",
  document: "SIGNATURE_PAGE",
  attachment: "ATTACHMENT",
  instapage: "INSTAPAGE",
  instapagev2: "INSTAPAGEV2",
}

const NullPage: UIItemPage = {
  isPersisted: true,
  isSignaturePage: false,
  isExecutedSignaturePage: false,
  number: 0,
  items: [],
  assignments: [],
  type: "document",
  id: "",
  forSigning: false,
  imageUrl: "",
}

type PagePreviewProps = {
  index?: number
  isOver?: boolean
  metaPage?: TPage["metaPage"]
  onCancel: () => void
  onToggleSignaturePageType: (metaPage: TPage["metaPage"]) => void
  pageType?: string
  position?: number
  startNumber: number
  endNumber?: number
  url?: string
}

export default function PagePreview({
  index,
  isOver,
  metaPage = NullPage,
  onCancel,
  onToggleSignaturePageType,
  pageType,
  url,
  position,
  startNumber,
  endNumber,
}: PagePreviewProps) {
  const forSigning = metaPage.forSigning || metaPage.isExecutedSignaturePage
  const isDocumentSignaturePage =
    metaPage.type === "document" && metaPage.isSignaturePage
  const isEmbeddedAttachment =
    metaPage.type === "attachment" &&
    metaPage.items.length > 0 &&
    metaPage.items.every((item) => item.pagesEmbeddedIn)

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      index,
      type: dragTypeForPage[metaPage.type] || "NONE",
      metaPage,
      pageType,
      position,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (_, monitor) => {
      if (!monitor.didDrop()) {
        onCancel()
      }
    },
  })

  const { item: draggingItem } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }))

  useEffect(() => {
    preview(getEmptyImage())
  }, [])

  type MetaPageValuesTypes = {
    [key: string]: {
      icon: BlueprintIcons_16Id | MaybeElement
      border: "blue" | "green"
      rotate?: boolean
      svg: string
      pageAmount?: number
      pageTagText?: string
      pageTagBg?: string
      pageTagColor?: string
    }
  }

  const metaPageValues: MetaPageValuesTypes = {
    inserted_page: {
      icon: "draw",
      border: "blue",
      rotate: true,
      svg: "/static/page-loading-3-borderless.svg",
    },
    attachment: {
      icon: isEmbeddedAttachment ? (
        <EmbeddedAttachmentsIcon className="w-5 h-5" />
      ) : (
        "paperclip"
      ),
      border: "blue",
      pageAmount: metaPage.items?.map((item) => item.pages?.length)[0],
      pageTagText: "Number of pages:",
      pageTagBg: "bg-[#E3ECFC]",
      pageTagColor: "text-[#4580E6]",
      svg: "/static/page-loading-4-borderless.svg",
    },
    instapage: {
      icon: "draw",
      border: "green",
      rotate: true,
      pageAmount: metaPage.assignments?.length,
      pageTagText: "Number of InstaPages:",
      pageTagBg: "bg-[#D9F2DB]",
      pageTagColor: "text-[#29A634]",
      svg: "/static/page-loading-2-borderless.svg",
    },
    instapagev2: {
      icon: "draw",
      border: "green",
      rotate: true,
      pageAmount:
        metaPage.assignments && metaPage.assignments.length > 1
          ? metaPage.assignments.length
          : 1,
      pageTagText: "Number of InstaPages:",
      pageTagBg: "bg-[#D9F2DB]",
      pageTagColor: "text-[#29A634]",
      svg: "/static/page-loading-2-borderless.svg",
    },
  }

  const canDropItemOnMe = !forSigning && !metaPage?.type

  if (isEmbeddedAttachment && !isDragging) {
    return (
      <div className="inline-flex space-x-5">
        {metaPage.items[0] &&
          metaPage.items[0]?.pages.map((page, index) => (
            <EmbeddedAttachmentsPagePreviews
              key={index}
              metaPage={metaPage}
              border={metaPageValues[metaPage.type]?.border}
              src={
                page.type !== "instapagev2"
                  ? page.imageUrl
                  : metaPageValues[metaPage.type]?.svg
              }
              isFirstPage={index === 0}
              pageTagBg={metaPageValues[metaPage.type]?.pageTagBg}
              pageTagColor={metaPageValues[metaPage.type]?.pageTagColor}
              pagesLength={metaPage.items[0]?.pages.length || 0}
              pageNumberLabel={startNumber + index}
              isDragging={isDragging}
              onToggleSignaturePageType={onToggleSignaturePageType}
              dragRef={dragRef}
            />
          ))}
      </div>
    )
  } else
    return (
      <div className="flex flex-shrink-0 flex-col mx-0.5">
        <div className="relative">
          <ThumbnailPreview
            border={
              (forSigning && metaPage.type !== "inserted_page") ||
              (isOver &&
                canDropItemOnMe &&
                draggingItem?.metaPage.type === "document")
                ? "green"
                : metaPage.type === "attachment" ||
                    metaPage.type === "inserted_page"
                  ? metaPageValues[metaPage.type]?.border
                  : undefined
            }
            className={
              isOver && draggingItem?.metaPage.type === "document"
                ? "shadow-lg"
                : ""
            }
            src={
              metaPage.type === "attachment" && "imageUrl" in metaPage
                ? metaPage.imageUrl
                : url
            }
            draggable={false}
            shadow={false}
            height="350px"
            width="260px"
          />
          {metaPage !== NullPage && !isDocumentSignaturePage && (
            <ThumbnailPreviewIcon
              className="absolute right-2 top-2"
              height="36px"
              width="36px"
            >
              <Icon
                icon={metaPageValues[metaPage.type]?.icon}
                className={
                  metaPageValues[metaPage.type]?.rotate ? "rotate-90" : ""
                }
                size={20}
              />
            </ThumbnailPreviewIcon>
          )}

          {metaPageValues[metaPage.type]?.pageAmount && (
            <div
              className={`absolute bottom-4 left-1/2 w-fit -translate-x-1/2 whitespace-nowrap rounded px-1.5 py-0.5 text-center text-sm ${metaPageValues[
                metaPage.type
              ]?.pageTagBg} ${metaPageValues[metaPage.type]?.pageTagColor}`}
            >
              {`${metaPageValues[metaPage.type]?.pageTagText} ${metaPageValues[
                metaPage.type
              ]?.pageAmount}`}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-center">
          <PageNumberLabel border={metaPageValues[metaPage.type]?.border}>
            {startNumber === endNumber
              ? startNumber
              : `${startNumber} - ${endNumber}`}
          </PageNumberLabel>
        </div>
        {(forSigning || metaPage.type === "attachment") && (
          <div className="mt-3 flex justify-center">
            <WithLabelForItemType item={metaPage}>
              <PageLabel
                ref={dragRef}
                isDragging={isDragging}
                type={metaPage.type}
                onTogglePageType={() => onToggleSignaturePageType(metaPage)}
              />
            </WithLabelForItemType>
          </div>
        )}
      </div>
    )
}
