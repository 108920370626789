import { ForwardedRef } from "react"
import { Icon, NumericInput } from "@blueprintjs/core"
import { ThumbnailPreviewIcon } from "components/item-detail/ui"
import { PageNumberLabel, ThumbnailPreview } from "components/pages"
import WithLabelForItemType from "./WithLabelForItemType"
import PageLabel from "./PageLabel"

import EmbeddedAttachmentsIcon from "icons/embedded-attachments.svg"
import { TPage } from "./VersionForm"

type EmbeddedAttachmentsPagePreviewsProps = {
  metaPage: TPage["metaPage"]
  border?: "green" | "blue"
  src?: string
  isFirstPage: boolean
  pageTagBg?: string
  pageTagColor?: string
  pagesLength: number
  pageNumberLabel: number
  isDragging?: boolean
  onToggleSignaturePageType: (metaPage: TPage["metaPage"]) => void
  dragRef?: ForwardedRef<HTMLDivElement>
}

export default function EmbeddedAttachmentsPagePreviews({
  metaPage,
  border,
  src,
  isFirstPage,
  pageTagBg,
  pageTagColor,
  pagesLength,
  pageNumberLabel,
  isDragging,
  onToggleSignaturePageType,
  dragRef,
}: EmbeddedAttachmentsPagePreviewsProps) {
  return (
    <div className="flex flex-col flex-shrink-0 mx-0.5">
      <div className="relative">
        <ThumbnailPreview
          border={border}
          src={src}
          draggable={false}
          shadow={false}
          height="350px"
          width="260px"
        />
        <ThumbnailPreviewIcon
          className="absolute right-2 top-2"
          height="36px"
          width="36px"
        >
          <Icon
            icon={<EmbeddedAttachmentsIcon className="w-5 h-5" />}
            size={20}
          />
        </ThumbnailPreviewIcon>
        {isFirstPage && (
          <div
            className={`absolute bottom-4 left-1/2 w-fit -translate-x-1/2 whitespace-nowrap rounded pl-1 pr-5 py-1 text-center text-sm ${pageTagBg} ${pageTagColor}`}
          >
            <div className="flex justify-center items-center space-x-1">
              <span>Number of pages:</span>
              <NumericInput
                small
                defaultValue={pagesLength}
                inputClassName="max-w-8 text-center"
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex justify-center">
        <PageNumberLabel border={border}>{pageNumberLabel}</PageNumberLabel>
      </div>
      {isFirstPage && metaPage && (
        <div className="mt-3 flex justify-center">
          <WithLabelForItemType item={metaPage}>
            <PageLabel
              ref={dragRef}
              isDragging={isDragging}
              type={metaPage.type}
              onTogglePageType={() => onToggleSignaturePageType(metaPage)}
            />
          </WithLabelForItemType>
        </div>
      )}
    </div>
  )
}
