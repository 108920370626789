import * as React from "react"
import { Button } from "@blueprintjs/core"
import cx from "classnames"

import UploadIcon from "icons/upload.svg"

import { useCurrentUser } from "features/auth/withAuth"
import DMSButton from "features/dms/Button"

type DocumentUploadCTAProps = {
  body?: React.ReactNode
  icon?: React.ReactNode
  onClickFromComputer?: () => void
  onClickFromDMS?: () => void
  orientation?: any
  title?: React.ReactNode
}

export default function DocumentUploadCTA({
  icon,
  orientation = "responsive",
  onClickFromComputer,
  onClickFromDMS,
  title = "Upload documents",
  body = `Drag and drop documents or click to select files from your document
            management system or computer.`,
}: DocumentUploadCTAProps) {
  const { currentUser } = useCurrentUser()

  const wrapperClasses = cx(
    "bg-white border-dashed border-2 border-blue-5 py-7 px-6 rounded-sm m-5",
    {
      "xl:flex-shrink-0 xl:ml-8 xl:pb-8 xl:w-88": orientation === "responsive",
      "w-full": orientation === "vertical",
    }
  )

  const iconClasses = cx("xl:sticky top-0 flex items-center", {
    "xl:flex-col": orientation === "responsive",
    "flex-col space-between h-full": orientation === "vertical",
  })

  const textClasses = cx("pl-4 flex-grow mr-auto text-center", {
    "xl:text-center xl:pl-0 xl:mb-6 xl:mt-3": orientation === "responsive",
  })

  const titleClasses = cx("mt-0 mb-2", {
    "xl:mb-5": orientation === "responsive",
  })

  return (
    <div className={wrapperClasses}>
      <div className={iconClasses}>
        {icon || (
          <UploadIcon
            className="flex-shrink-0"
            width="100px"
            height="100px"
            viewBox="0 0 35 35"
          />
        )}
        <div className={textClasses}>
          <h2 className={titleClasses}>{title}</h2>
          <p className="color-gray-1 mb-2 px-4 text-sm">{body}</p>
        </div>
        <div className="ml-4 mr-2 flex flex-shrink-0 flex-col">
          {currentUser?.hasDMS && onClickFromDMS && (
            <>
              <DMSButton className="px-4" onClick={onClickFromDMS} />
              <div className="py-2" />
            </>
          )}
          <Button className="px-4" onClick={onClickFromComputer}>
            From computer
          </Button>
        </div>
      </div>
    </div>
  )
}
