const decrementPlaceholderMap = <P extends PageInput>({
  pages,
  placeholders,
  itemPage,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [
    pages,
    [
      {
        pageType: "placeholder",
        metaPage: itemPage,
        pageIndex: Math.max(0, docPageIndex + 1),
      },
      ...placeholders,
    ],
  ],
  docPageIndex, // Do not increment doc index as we are inserted attachment placeholder page
  itemPageIndex - 1,
]

const incrementPlaceholderMap = <P extends PageInput>({
  pages,
  placeholders,
  itemPage,
  docPageIndex,
  itemPageIndex,
  thumbnailLength,
}: MapFnArgs<P>): MapFnResult<P> => {
  return [
    [
      pages,
      [
        ...placeholders,
        {
          pageType: "placeholder",
          metaPage: itemPage,
          pageIndex: thumbnailLength ? thumbnailLength : docPageIndex,
        },
      ],
    ],
    docPageIndex, // Do not increment doc index as we are inserted attachment placeholder page
    itemPageIndex + 1,
  ]
}

const incrementPageMap = <P extends PageInput>({
  pages,
  placeholders,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [pages, placeholders],
  docPageIndex - 1,
  itemPageIndex - 1,
]

const decrementItemPageMap = <P extends PageInput>({
  pages,
  placeholders,
  docPage,
  itemPage,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [
    [
      {
        index: docPageIndex,
        url: docPage ? docPage : itemPage?.imageUrl || "",
        pageType: "page",
        metaPage: itemPage,
      },
      ...pages,
    ],
    placeholders,
  ],
  docPageIndex - 1,
  itemPageIndex - 1,
]

const incrementItemPageMap = <P extends PageInput>({
  pages,
  placeholders,
  docPage,
  itemPage,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [
    [
      ...pages,
      {
        index: docPageIndex,
        url: docPage ? docPage : itemPage?.imageUrl || "",
        pageType: "page",
        metaPage: itemPage,
      },
    ],
    placeholders,
  ],
  docPageIndex + 1,
  itemPageIndex + 1,
]

const decrementDocPageMap = <P extends PageInput>({
  pages,
  placeholders,
  docPage,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [
    [{ index: docPageIndex, url: docPage || "", pageType: "page" }, ...pages],
    placeholders,
  ],
  docPageIndex - 1,
  itemPageIndex - 1,
]

const incrementDocPageMap = <P extends PageInput>({
  pages,
  placeholders,
  docPage,
  docPageIndex,
  itemPageIndex,
}: MapFnArgs<P>): MapFnResult<P> => [
  [
    [...pages, { index: docPageIndex, url: docPage || "", pageType: "page" }],
    placeholders,
  ],
  docPageIndex + 1,
  itemPageIndex + 1,
]

type PageIsh = {
  isExecutedSignaturePage: boolean
  type:
    | "inserted_page"
    | "document"
    | "attachment"
    | "instapage"
    | "instapagev2"
}

function applyExecSigPageType<P extends PageIsh>(page: P): P {
  if (page.isExecutedSignaturePage) {
    return { ...page, type: "inserted_page" }
  }
  return page
}

export function undoExecSigPageType<P extends PageIsh>(
  page: P | undefined
): P | undefined {
  if (!page) return

  if (page.type === "inserted_page") {
    return { ...page, type: "document" }
  }
  return page
}

type MapFnArgs<P> = {
  pages: VersionPage<P>[]
  placeholders: Placeholder<P>[]
  docPage: string | undefined
  itemPage: P | undefined
  docPageIndex: number
  itemPageIndex: number
  thumbnailLength?: number
}

type MapFnResult<P> = [[VersionPage<P>[], Placeholder<P>[]], number, number]

type MapFn<P> = (args: MapFnArgs<P>) => MapFnResult<P>

type PageInput = {
  type:
    | "document"
    | "attachment"
    | "instapage"
    | "instapagev2"
    | "inserted_page"
  imageUrl: string
  isExecutedSignaturePage: boolean
  forSigning?: boolean
}

export type VersionPage<P> = {
  index: number
  metaPage?: P
  pageType: "page"
  url: string
}

export type Placeholder<P> = {
  index: number
  pageType: "placeholder"
  metaPage: P | undefined
  pageIndex: number
}

export default function mapThumbnailsToPages<P extends PageInput>(
  thumbnails: string[],
  itemPagesInput: P[],
  isPlaceholderItem?: boolean
): [VersionPage<P>[], Placeholder<P>[]] {
  let itemPages = itemPagesInput.map(applyExecSigPageType)

  let originalPagesLength = itemPages.filter(
    (p) => p.type === "document"
  ).length

  let isNewVersionShorter = thumbnails.length < originalPagesLength

  let startDocPageIndex = isNewVersionShorter ? thumbnails.length - 1 : 0

  let startItemPageIndex = isNewVersionShorter ? itemPages.length - 1 : 0

  function partitionPages(
    [pages = [], placeholders = []]: [VersionPage<P>[], Placeholder<P>[]] = [
      [],
      [],
    ],
    docPageIndex = 0,
    itemPageIndex = 0
  ): [VersionPage<P>[], Placeholder<P>[]] {
    let docPage = thumbnails[docPageIndex]
    let itemPage = itemPages[itemPageIndex]
    let thumbnailLength = isPlaceholderItem ? thumbnails.length : undefined

    let map = (fn: MapFn<P>) =>
      partitionPages(
        ...fn({
          pages,
          placeholders,
          docPage,
          itemPage,
          docPageIndex,
          itemPageIndex,
          thumbnailLength,
        })
      )

    if (
      itemPage?.type === "attachment" ||
      itemPage?.type === "inserted_page" ||
      itemPage?.type === "instapage" ||
      itemPage?.type === "instapagev2"
    ) {
      return map(
        isNewVersionShorter
          ? decrementPlaceholderMap<P>
          : incrementPlaceholderMap<P>
      )
    }

    if (itemPage?.forSigning || (!docPage && itemPage?.type)) {
      if (isNewVersionShorter) {
        return map(!docPage ? incrementPageMap<P> : decrementItemPageMap<P>)
      }
      return map(incrementItemPageMap)
    }

    if (docPage) {
      return map(
        isNewVersionShorter ? decrementDocPageMap : incrementDocPageMap
      )
    }

    return [pages, placeholders]
  }

  return partitionPages([[], []], startDocPageIndex, startItemPageIndex)
}
