import { DistributiveOmit, Override } from "lib/ts/override"
import { BuiltPage, BuiltPageItem } from "./BuiltPage"
import { Item } from "./Item"

export type UIItemPage = DistributiveOmit<BuiltPage, "items"> & {
  isPersisted: boolean
  isSignaturePage: boolean
  isExecutedSignaturePage: boolean
  number: number
  items: BuiltPageItem[]
}

type UIItemFields = {
  hasAssignments: boolean
  pages: UIItemPage[]
}

type UIItemInput = Override<Item, { pages: BuiltPage[] }>

export type UIItem = Override<UIItemInput, UIItemFields>

export function itemForUI(item: UIItemInput | undefined): UIItem | undefined {
  if (!item) return
  return {
    ...item,
    hasAssignments: item.pages.some((p) => (p.assignments || []).length > 0),
    pages: item.pages.map((page, index) => {
      if (page.type === "instapagev2") {
        return {
          ...page,
          isPersisted: true,
          isSignaturePage: true,
          isExecutedSignaturePage: false,
          number: index + 1,
          items: [],
        }
      }

      return {
        ...page,
        refId: page.refId,
        refPageNumber: page.refPageNumber,
        type: page.type,
        forSigning: page.forSigning,
        assignments: page.assignments,
        items: page.items || [],
        id: page.id,
        number: index + 1,
        isSignaturePage: page.forSigning || false,
        imageUrl: page.imageUrl,
        isPersisted: true,
        isExecutedSignaturePage: Boolean(page.isExecutedSignaturePage),
        itemsConfig: page.itemsConfig,
      }
    }),
  }
}
