import { Override } from "lib/ts/override"
import { BuiltPage } from "models/BuiltPage"
import { InstaPageV2 } from "models/InstaPageV2"
import { Page, PageV1 } from "models/Pages"

type NormalPage = Override<
  PageV1,
  {
    assignments: { id: string }[]
    items: { id: string }[] | undefined
  }
>
const attachmentPageEncoder = ({
  id,
  type,
  label,
  name,
  items = [],
  itemsConfig,
  number,
}: NormalPage): PageV1 => ({
  id,
  type,
  label,
  name,
  items: items.map(({ id }) => id),
  itemsConfig,
  number,
})

const documentPageEncoder = ({
  id,
  forSigning,
  type,
  refId,
  refPageNumber,
  assignments = [],
  isExecutedSignaturePage = false,
  number,
}: NormalPage): PageV1 => ({
  id,
  forSigning: forSigning || false, // make sure boolean is sent
  type,
  refId,
  refPageNumber,
  assignments: assignments.map(({ id }) => id),
  isExecutedSignaturePage,
  number,
})

const instapageEncoder = ({
  id,
  type,
  enabled,
  footer,
  leadIn,
  everyLeadIn,
  breakAwayPages,
  replacePage,
  date,
  email,
  notary,
  address,
  witness,
  assignments = [],
  number,
}: NormalPage): PageV1 => ({
  id,
  forSigning: true,
  type,
  enabled,
  footer,
  leadIn,
  everyLeadIn,
  breakAwayPages,
  replacePage,
  date,
  email,
  notary,
  address,
  witness,
  assignments: assignments.map(({ id }) => id),
  number,
})

type BuiltInstaPageV2 = Override<
  InstaPageV2,
  {
    assignments: { id: string }[]
  }
>

const instapageV2Encoder = ({
  id,
  type,
  margins,
  font,
  consolidateSigner,
  blocks,
  signaturePageType,
  assignments,
  fontSize,
  number,
}: BuiltInstaPageV2): InstaPageV2 => ({
  id,
  forSigning: true,
  type,
  margins,
  font,
  consolidateSigner,
  blocks,
  signaturePageType,
  assignments: assignments.map(({ id }) => id),
  fontSize,
  number,
})

export function encodePage(input: BuiltPage): Page {
  switch (input.type) {
    case "attachment":
      return attachmentPageEncoder(input)
    case "document":
      return documentPageEncoder(input)
    case "instapage":
      return instapageEncoder(input)
    case "instapagev2":
      return instapageV2Encoder(input)
  }
}
